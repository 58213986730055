import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Suspense as _Suspense, openBlock as _openBlock, createBlock as _createBlock, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center my-5" }
const _hoisted_2 = { class: "card col-12 col-md-6 col-lg-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidFiatCurrencySelector = _resolveComponent("ValidFiatCurrencySelector")!
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DualRingLoader, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("form", {
              class: "",
              onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Submit && _ctx.Submit(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", {
                    for: "selector",
                    class: "form-label"
                  }, "Select Currency", -1)),
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.code
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ValidFiatCurrencySelector, {
                            id: "selector",
                            modelValue: _ctx.formData.code,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.code) = $event)),
                            required: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ]),
                    fallback: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Loading card...")
                    ])),
                    _: 1
                  }))
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", null, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", {
                    for: "name",
                    class: "form-label"
                  }, "Currency Name", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.name
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.name) = $event)),
                        placeholder: "Currency Readable Name",
                        class: "form-control",
                        id: "name",
                        required: ""
                      }, null, 512), [
                        [_vModelText, _ctx.formData.name]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", {
                    for: "we_buy",
                    class: "form-label"
                  }, "We Buy $ at", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.we_buy
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.we_buy) = $event)),
                        type: "number",
                        placeholder: "0.00",
                        class: "form-control",
                        id: "we_buy",
                        required: ""
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.formData.we_buy,
                          void 0,
                          { number: true }
                        ]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", {
                    for: "we_sell",
                    class: "form-label"
                  }, "We Sell $ at", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.we_sell
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.we_sell) = $event)),
                        type: "number",
                        placeholder: "0.00",
                        class: "form-control",
                        id: "we_sell",
                        required: ""
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.formData.we_sell,
                          void 0,
                          { number: true }
                        ]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-center" }, [
                _createElementVNode("button", { class: "btn btn-dark" }, "Create Fiat Currency")
              ], -1))
            ], 32)
          ])
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}