import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueCopy) = $event)),
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', _ctx.valueCopy))),
    class: "form-select form-select-lg",
    "aria-label": ""
  }, [
    _cache[2] || (_cache[2] = _createElementVNode("option", {
      disabled: "",
      selected: "",
      value: null
    }, "Select Currency", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencies, (item, index) => {
      return (_openBlock(), _createElementBlock("option", {
        value: item,
        key: index
      }, _toDisplayString(item), 9, _hoisted_1))
    }), 128))
  ], 544)), [
    [_vModelSelect, _ctx.valueCopy]
  ])
}